
import {Route, NavLink, Switch, BrowserRouter, useHistory} from "react-router-dom"
import HomePage from './Components/HomePage/HomePage';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink as NavLink2, Container } from 'reactstrap';
import React, { Component } from 'react';
import FooterPage from './Components/FooterPage/FooterPage';
import HomePageView from "./Views/HomePageView";
import RequestView from "./Views/RequestView";
import MailingView from "./Views/MailingView";
import AuthentView from "./Views/AuthentView";
import SocialShareView from "./Views/SocialShareView";
import ContactView from "./Views/ContactView"
import UserLounge from './Components/UserLounge/UserLounge'
import StickyMenu3 from "./Components/StickyMenu3/StickyMenu3";
import ChatbotForm from './Components/ChatbotForm/ChatbotForm';
import DailyWorshipView from './Views/DailyWorshipView'
import BookletsView from './Views/BookletsView'
import AboutPageView from "./Views/AboutPageView";
import SubscribeView from "./Views/SubscribeView";
import ParticipationView from "./Views/ParticipationView";
import BiblePageView from "./Views/BiblePageView"
import "./Components/HomePage/HomePage.css";
import { Button} from 'reactstrap';
import { globals, onClickAssistantHelper } from "./Components/Globals/Globals";


class App extends Component {
  constructor(props){
    super(props);
    this.state = {collapsed:true, chatbot_help_collapsed:globals.parameters.assistant_helper_collapsed}
  }

  toggleNavbar = () => this.setState({collapsed:!this.state.collapsed});

  render = () =>{
    return (
      <BrowserRouter>
        <div id="menu">
            <StickyMenu3/>
        </div>
        
        {/* Router page content */}
        <div className="content">
          <Route exact path = "/" component={HomePageView} />
          <Route exact path = "/authentview" component={AuthentView} /> {/* Route without parameter */}
          <Route path = "/userlounge/:uuidrequetes" component={UserLounge} /> {/* Route with parameters */}
          <Route path = "/mailingview/:uuidrequetes" component={MailingView} /> {/* Route with parameters */}
          <Route path = "/priere" component={RequestView} />
          <Route path = "/socialshare" component={SocialShareView} />
          <Route path = "/dailyworship" component={DailyWorshipView} />
          <Route path = "/contact" component={ContactView} />
          <Route path = "/booklets" component={BookletsView} />
          <Route path = "/about" component={AboutPageView} />
          <Route path = "/subscription" component={SubscribeView}/>
          <Route path = "/participation" component={ParticipationView}/>
          <Route path = "/bibleread" component={BiblePageView} />
        </div>
          <ChatbotForm
            headerTitle="Assistant de requête"
            opened={false}
          />
          <div className={`help-assist ${this.state.chatbot_help_collapsed?"assist-close":""}`} onClick={(e)=>{onClickAssistantHelper(e)}}>Puis-je vous aider ?</div>

        {/* Footer */}
        <FooterPage/>
      </BrowserRouter>
    );
  }
}

export default App;
