/*
GMlookupCombo Component
*/

import React, {Component, } from 'react';
import axios from 'axios';
import {DropdownMenu, DropdownItem, ButtonDropdown, DropdownToggle, Input} from "reactstrap";
import './GMlookupCombo3.css';

class GMlookupCombo3 extends Component{
    constructor(props){
        super(props);
        this.state = {items:[], selected_object:null, open:false, filter:""}
    }

    componentDidMount = () => {
        this.fetchItems();
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        
        if (this.props.getLookupParameterValue !== prevProps.getLookupParameterValue) {
            //Lookup field props has changed
            this.fetchItems();//WE SHOULD RATHER APPLY A FILTER ON WHOLE DATABASE STREAM RESULT !!!
        }

        if (this.props.activeSelectionKeyValue !== prevProps.activeSelectionKeyValue) {
            //Active selection has changed from external
            this.setState({selected_object:this.finditemsObjectWithKey(this.props.activeSelectionKeyValue)});
        }
    }

    fetchItems = async () => {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        const serviceURL = (["localhost", "127.0.0.1"].includes(window.location.hostname))?this.props.localURL:this.props.remoteURL
        const serviceURLlookUp = `${serviceURL}?${this.props.getLookupParameterName}=${this.props.getLookupParameterValue}`//Build get request with lookup filter
        const fetchresult = await axios.get(this.props.getLookupParameterName?serviceURLlookUp:serviceURL,this.state);//2DO debug post

        if (fetchresult.data.state == true){
            //Insert none selection items choice
            if (this.props.blankSelectionItem && this.props.userCanChooseBlankSelection){
                //insert this default No-choice item
                if (fetchresult.data){
                    fetchresult.data.items = [this.props.blankSelectionItem, ...fetchresult.data.items]
                }
                else{
                    console.error("GMlookupCombo fetchresult is empty !")
                }   
            }

            //Store items in current component state
            this.setState({items : fetchresult.data.items}, () => {
                    //Set drop down with active selection when given
                    if (this.props.activeSelectionKeyValue){
                        this.setState({selected_object:this.finditemsObjectWithKey(this.props.activeSelectionKeyValue)});
                    }
                    else this.setState({selected_object:null}) //reset selection anyway
                });
        }
        else {
            alert("Fetch result failed with error" + fetchresult.data.message)
        }
    };

    finditemsObjectWithKey = (key_searched) => {//Look for items object given a key_searched
        let ii = 0;
        while (ii < this.state.items.length){
            if (this.state.items[ii][this.props.keyFieldName] == key_searched) return this.state.items[ii];
            ii++;
        }
        return null;
    }

    onFilterChange = (e) => {
        this.setState({filter:e.target.value})
    }

    itemsObjectRendered = (itemsObject) => {
        if (!itemsObject){
            if (this.props.promptSelectionTitle){
                //Return user-custom default text where none selection has been set
                return(<span>{this.props.promptSelectionTitle}</span>)
            }
            else{
                //Return built-in default text
                return(<span>Faites une sélection ...</span>)
            }
        }            
        else
            return(
                    <span>&nbsp;{itemsObject[this.props.fieldName]}</span>  
            );
    }
    
    itemsDropDownRenderer = () => {
        const renderedDropDown = Object.values(
            this.state.items.filter((items) => items[this.props.fieldName].toLowerCase().indexOf(this.state.filter.toLowerCase(),0) == 0)
            ).map(
            itemsObjectItem => {
                return (
                    <DropdownItem 
                        key={itemsObjectItem[this.props.keyFieldName]} 
                        onClick = {() => { 
                                            this.setState({selected_object:itemsObjectItem}); 
                                            if (this.props.OnSelect) this.props.OnSelect(itemsObjectItem); 
                                            else alert("OnSelect callback is not  defined");}}>
                        {this.itemsObjectRendered(itemsObjectItem)}
                    </DropdownItem>
                )
            }
        )

        return(
            <div>
                <ButtonDropdown isOpen={this.state.open} toggle={() => {this.setState({open:!this.state.open});}}>
                    <DropdownToggle caret>
                        {this.itemsObjectRendered(this.state.selected_object)}
                    </DropdownToggle>
                        
                    <DropdownMenu>
                        {this.props.searchEnabled == true &&
                        <input 
                                autoFocus 
                                id="dropdown_input_filter" 
                                onChange={(e) => {this.onFilterChange(e);}}type="search" 
                                value={this.state.filter} 
                                placeholder="Filtre de recherche ..."></input>}
                        {renderedDropDown}
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
        )
    }

    render = () => {
        return(
            <div>
                {(this.state.items.length > 0) && this.itemsDropDownRenderer()}
            </div>
        )
    }
}

export default GMlookupCombo3;