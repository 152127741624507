import React, { Component} from 'react';
import { Button, Input,Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './ContactView.css'
import InputForm2 from '../Components/InputForm2/InputForm2'
import InputForm3 from '../Components/InputForm3/InputForm3'
import axios from "axios";
import {onClickAssistantHelper} from '../Components/Globals/Globals'
import {Route, NavLink, Switch, BrowserRouter, useHistory} from "react-router-dom"

class ContactView extends Component{
    constructor(props){
        super(props)

        this.clearObject = {
            nom:"",
            prenom:"",
            email:"",
            message:"",
            err_nom:[], err_prenom:[], err_email:[], err_message:[], err_captcha:[],
            has_changed:false,
            submitted:false,
            captcha:""
        }

        this.state = this.clearObject;
    }

    componentDidMount = () =>{
        this.clearForm()
        this.handle_nom = this.handle_nom.bind(this)
        this.handle_prenom = this.handle_prenom.bind(this)
        this.handle_email = this.handle_email.bind(this)
        this.handle_message = this.handle_message.bind(this)
        this.handle_captcha = this.handle_captcha.bind(this)
    }

    onSubmitFormHandler  = async event => {
        event.preventDefault();

        //Force all fields check
        let canSubmit = this.checkValidation('nom')
        canSubmit &= this.checkValidation('prenom')
        canSubmit &= this.checkValidation('email')
        canSubmit &= this.checkValidation('message')
        canSubmit &= this.checkValidation('captcha')

        console.log("canSubmit:", canSubmit)

        if (!canSubmit) return false;

        const localURL = 'http://localhost:5100'
        const remoteURL = 'https://mailer.jesusestlareponsepourtous.org'
        let service_db_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localURL:remoteURL

        console.log("micro-service => service_db_url:", service_db_url," this.state =>", this.state);
    
        //Populate contact table with new one
        var serviceResult = null
        var mailResult = null

        try{
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            serviceResult = await axios.post(`${service_db_url}/add-contact`,
            this.state);
        } catch(err){
            alert(`Verifiez votre connexion wifi : ${err}`);
            return;
        }
    
        //Get service Acknowledge before resetting state values.
        console.log("add-contact returns :", serviceResult.data);
    
        //Send Acknoledge mail back to user
        if (serviceResult.data.state != true){
            alert("Erreur:" + serviceResult.data.message);
        }
        else
        try{
                //Post confirmation e-mail back to user
                //const mailResult = await axios.post(`${service_db_url}/acknowledge-contact`,
                mailResult = await axios.post(`${service_db_url}/acknowledge-contact`,
                    {
                        from:"ne-pas-repondre@jesusestlareponsepourtous.org",
                        email:this.state.email,
                        subject:"Accusé de réception de votre message",
                        prenom:this.state.prenom,
                        nom:this.state.nom,
                    }
                );

                console.log("mailResult =>", mailResult);
                //Display confirmation number
                alert(`Un courriel de confirmation vous a été envoyé`)

                //Broadcast user mail message to jlrpt mailing staff
                /*
                if (mailResult.data.state != true){
                    alert("Erreur:" + mailResult.data.message);
                }
                else*/
                try{
                    //Post confirmation e-mail back to user
                    const broadcastResult = await axios.post(`${service_db_url}/mail-broadcast`,
                        {
                            from:"ne-pas-repondre@jesusestlareponsepourtous.org",
                            email:"jlrpt_contact_group@neuronal.bible",
                            email_user:this.state.email,
                            subject:"Nouveau message posté depuis JLRPT.com",
                            prenom:this.state.prenom,
                            nom:this.state.nom,
                            message:this.state.message
                        }
                    );

                    console.log("broadcastResult =>", broadcastResult);
                    //Display confirmation number
                    console.log(`Courriel d'origine diffusé à l'équipe`)

                    //Clear form
                    this.clearForm()
                } catch(err){
                    alert(`Une erreur est survenue :${err}`);
                    return;
                }

                //Clear form
                this.clearForm()
        } catch(err){
            alert(`Une erreur est survenue :${err}`);
            return;
        }
    }

    checkValidation = (fieldName, read_only = false) => {
        let max = 45
        let min = 2
        
        let errorsFieldName = `err_${fieldName}`
        let nextState = this.state
        let nextErrors = this.state[errorsFieldName]
        nextErrors = []

        switch (fieldName) {
            case "nom" :     
                min = 2           
                max = 45
                
                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "prenom" :
                min = 2
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "email" :
                min = 5
                max = 45

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)

                if (!this.state[fieldName].match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
                    nextErrors.push(`non valide`)
                break

            case "message" :
                min = 2
                max = 145

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                break

            case "captcha" :
                min = 1
                max = 2

                if (this.state[fieldName].trim().length < min)
                    nextErrors.push(`trop court.`)

                if (this.state[fieldName].length > max)
                    nextErrors.push(`trop long, ${this.state[fieldName].length - max} caractères en trop.`)
                
                if (parseInt(this.state[fieldName]) !== (this.state.v1 + this.state.v2))
                    nextErrors.push(`calcul faux !`)
                break
        }

        //update error field
        nextState[errorsFieldName] = nextErrors

        //set change flag
        nextState.has_changed = true

        //update internal state
        if (!read_only) //Avoid recursive setstate
            this.setState(nextState)

        //Return validation predicate
        return (nextErrors.length === 0) //returns true if no error occurs
    }

    handle_nom = e => {
        e.preventDefault();

        this.setState({nom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_prenom = e => {
        e.preventDefault();

        this.setState({prenom:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_email = e => {
        e.preventDefault();

        this.setState({email:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_message = e => {
        e.preventDefault();

        this.setState({message:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    handle_captcha = e => {
        e.preventDefault();

        this.setState({captcha:e.target.value}, ()=>{this.checkValidation(e.target.name)})
    }

    randomize = (min, max) => {
        return Math.floor(min + Math.random() * (max - min));
    }

    clearForm = () => {
        this.clearObject = Object.assign(this.clearObject, {v1:this.randomize(0,5), v2:this.randomize(0,5)})
        this.setState(this.clearObject, ()=>{console.log("state cleaned:", this.state)});
    }

    validateForm  = () =>{
        return (
            this.state.has_changed
            && this.checkValidation('nom', true)
            && this.checkValidation('prenom', true)
            && this.checkValidation('email', true)
            && this.checkValidation('message', true)
            && this.checkValidation('captcha', true)
        )
    }
    
    render = () => {
        return(
            <div className="contact-page-container">
                {/* Full width main picture */}
                <div className="top-banner-image">
                    <div className="nested-title">Nous contacter</div>
                </div>

                <div className="body-page-container">
                    {/* Welcome Panel */}
                    <div className="welcome-title">
                        <div>
                            Formulaire de contact
                        </div>
                    </div>

                    {/* Laïus introduction de la page*/}
                    <div className="introduction-container">
                        <p>Vous avez une idée, une suggestion pour nous aider à faire croître la mission ?
                        <br/>N'hésitez pas à nous écrire un mail !</p>
                        <p>
                        <hr/>
                        <strong>Important !</strong> nous ne traitons plus les sujets de prière par mail.
                        <br/> Nous vous invitons à utiliser <i>l'assistant</i> ou le <i>formulaire de soumission</i> de prières. 
                        <br/>Merci pour votre compréhension.
                        <br/>
                        <br/>
                        <Button onClick={(e)=>{onClickAssistantHelper(e)}}>Je soumets par l'assistant</Button>
                        <br/>
                        <br/>
                        <NavLink to="/priere">
                            <Button renderAs="button">
                                <span>Je soumets via le formulaire</span>
                            </Button>
                        </NavLink>
                        </p>
                        <hr/>
                    </div>

                    <form id="contact-form" onSubmit={this.onSubmitFormHandler}>
                        <div className="form-group row col-md-12">
                            <InputForm2 
                                label={"Nom"} 
                                name={"nom"} 
                                value={this.state.nom} 
                                onChange={this.handle_nom} 
                                type={"text"} 
                                placeholder={"Votre nom"}
                                error={this.state.err_nom}
                            />

                            <InputForm2 
                                label={"Prénom"} 
                                name={"prenom"} 
                                value={this.state.prenom} 
                                onChange={this.handle_prenom} 
                                type={"text"} 
                                placeholder={"Votre prénom"}
                                error={this.state.err_prenom}
                            />
                        </div>

                        <div className="form-group row col-md-12">
                            <InputForm2 
                                label={"email"} 
                                name={"email"} 
                                value={this.state.email} 
                                onChange={this.handle_email} 
                                type={"text"} 
                                placeholder={"Votre adresse e-mail"}
                                error={this.state.err_email}
                            />

                            <InputForm3 
                                label={"Message"} 
                                name={"message"} 
                                value={this.state.message} 
                                onChange={this.handle_message} 
                                type={"textarea"} 
                                placeholder={"Votre message"}
                                error={this.state.err_message}
                            />
                        </div>

                        <div className="form-group row col-md-12">
                            <InputForm2 
                                label={`Je ne suis pas un robot, je calcule: ${this.state.v1} + ${this.state.v2}`} 
                                name={"captcha"} 
                                value={this.state.captcha} 
                                onChange={this.handle_captcha} 
                                type={"text"} 
                                placeholder={`Combien font ${this.state.v1} + ${this.state.v2} ?`}
                                error={this.state.err_captcha}
                            />
                        </div>
                    </form>
                

                    <ModalFooter>
                        {/*<label className="col-sm-4 col-form-label" hidden={!this.state.submitted}><strong>Message envoyé...</strong></label>*/}

                        <Button color="secondary" onClick={(e) => {this.clearForm();}} hidden={!this.state.has_changed}>Tout effacer</Button>
                        <Button color={this.validateForm()?"primary":"danger"} form="contact-form">{this.validateForm()?"Envoyer le message":"Controler ma saisie !"}</Button>
                    </ModalFooter>
                </div>
            </div>)
    }
}

export default ContactView;