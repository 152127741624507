import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import ChatBot from 'react-simple-chatbot';
import axios from 'axios';
import botAvatarPicture from "../../Assets/img/favicon_package_v0.16/favicon-32x32.png"

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      target: '',
      category: '',
      target_message:'',
      subject:''
    };
  }

  componentWillMount() {
    const { steps } = this.props;
    const { name, target, category, email, target_message, subject } = steps;

    this.setState({ name, target, category, email, target_message, subject });
  }

  render() {
    const { name, target, category, email, target_message, subject } = this.state;
    return (
      <div>
        <p>
            Ok <b>{name.value}</b> !
        </p>
        <p>
          Votre email est <b>{email.value}</b>.
        </p>
            
        {(target_message.value == "other") &&
        <p>
          Vous nous demandez de prier pour <b>{target.value}</b>
        </p>}    
            
        {(target_message.value == "me") &&
        <p>
          Vous nous demandez de prier pour <b>vous-même</b>
        </p>}
         
        <p>
          Dans la catégorie:<br/> 
          <b>{category.message}</b>
        </p>
         
        <p>
          Le sujet est:<br/> 
          <b>{subject.message}</b>
        </p>

      </div>
    );
  }
}

Review.propTypes = {
  steps: PropTypes.object,
};

Review.defaultProps = {
  steps: undefined,
};

class ChatBotSumbmit extends Component {
  constructor(props) {
    super(props);

    this.submit_state = {pending:-1, failed:1, success:0}

    this.state = {
      name: '',
      target: '',
      category: '',
      email: '',
      subject:'',
      err:this.submit_state.pending
    };
  }

  componentWillMount() {
    console.log("ChatbotSubit::componentWillMount");
    const { steps } = this.props;
    const { name, target, category, email, target_message, subject } = steps;

    this.setState({ name, target, category, email, target_message, subject });
  }

  componentDidMount = () => {
    console.log("ChatbotSubit::componentDidMount");
    this.onSubmitProcess();
  }

  toastError = error_message => {
    this.setState({message:error_message, err:this.submit_state.failed})
    if (this.props.onFailed) this.props.onFailed(error_message);
  }

  toastMessage = message => {
    this.setState({message:message, err:this.submit_state.success})
    if (this.props.onSuccess) this.props.onSuccess(message);
  }

  onSubmitProcess  = async event => {
    const localURL = 'http://localhost:4001/req-add-proc'
    const remoteURL = 'https://requetes.jesusestlareponsepourtous.org/req-add-proc'
    let service_db_url = ["localhost", "127.0.0.1"].includes(window.location.hostname)?localURL:remoteURL
    this.setState({err:this.submit_state.pending, message:""})

    //call requete-service
    var serviceResult = null
    try{
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        //Get relevant properties
        console.clear();
        console.log("ChatBotSumbmit::state:", this.state)

        let requeteData = {
          nom : "",//this.state.name.value,
          prenom : this.state.name.value,
          email: this.state.email.value,
          //sujet: `${this.state.category.message} (créée avec l'assistant ChatBot)`,
          sujet: this.state.subject.message,
          beneficiaire: (this.state.target_message.value == "other")?this.state.target.value:this.state.name.value,
          idrubriques: this.state.category.value,
          country_code: "FR" //74
        }

        console.log("requeteData:", requeteData)
        
        serviceResult = await axios.post(service_db_url,requeteData);
    } catch(err){
        this.toastError(`Verifiez votre connexion wifi : ${err}`);
        return;
    }

    //Get service aknowledge before resetting state values.
    console.log("serviceResult ==>", serviceResult)
    if (serviceResult.data.state != true){
        this.toastError("Erreur:" + serviceResult.data.message);
    }
    else
    try{
        this.setState({dossier:serviceResult.data.items[0]});
        console.log("state on acknowledge:", this.state)

        let regexpUUID =  /^(.*?)-/g;
        let match = regexpUUID.exec(this.state.dossier.uuidrequetes_log);

        if (match) {
            //Compute short_uuid
            this.setState({short_uuid:match[1]});

            //Post confirmation e-mail back to user
            const mailResult = await axios.post('https://mailer.jesusestlareponsepourtous.org/sendconfirmation',
                {
                    from:"ne-pas-repondre@jesusestlareponsepourtous.org",
                    email:this.state.email.value,
                    subject:"Accusé de réception de votre demande de prière",
                    prenom:this.state.name.value,
                    nom:"",//this.state.name.value,
                    dossier:this.state.short_uuid,
                    jour:this.state.dossier.jour.padStart(2, '0'),
                    mois:this.state.dossier.mois.padStart(2, '0'),
                    annee:this.state.dossier.annee,
                    heure:this.state.dossier.heure,
                    minutes:this.state.dossier.minutes.padStart(2, '0'),
                    user_lounge_url:`https://jlrpt.com?uuid=${this.state.dossier.uuidrequetes_log}&user_mail=${this.state.email.value}`
                }
            );

            //Display confirmation number
            this.toastMessage(`Un courriel de confirmation vous a été envoyé sous la référence: \r\n(${this.state.short_uuid})`)

            //Clear form
            if(this.props.onSuccess) this.props.onSuccess();
        }
        else{
            this.toastError("Erreur:" + serviceResult.data.message);
        }
    } catch(err){
        this.toastError(`Une erreur est survenue :${err}`);
        return;
    }
}  


  render() {
    const { name, target, category, email } = this.state;
    return (
      <div>
        <div>
          {(this.state.err == this.submit_state.pending) && <h5 style={{color:'orange'}}>Soumission...</h5>}
          {(this.state.err == this.submit_state.failed) && <h5 style={{color:'red'}}>Echec de soumission</h5>}
          {(this.state.err == this.submit_state.success) && <h5 style={{color:`blue`}}>Soumission réussie</h5>}
          {this.state.message}
        </div>
      </div>
    );
  }
}

class ChatBotForm extends Component {

  checkName = (value) => {
    //return value.match(/[a-zA-Zéôüïî¨öêèéÄËÏÖÜ]+[-_]*[a-zA-Zéôüïî¨öêèéÄËÏÖÜ]+/i)
    console.clear()
    
    let trimmedValue = value.trim();
    let matchResult = trimmedValue.match(/^[a-zA-Zéôüïî¨öêèéÄËÏÖÜ]+[\s-_a-zA-Zéôüïî¨öêèéÄËÏÖÜ]*/i);
    let valueValidate = (matchResult && (matchResult[0] == trimmedValue));

    console.log("value:",value)
    console.log("trimmedValue:",trimmedValue)
    console.log("matchResult:",matchResult)
    console.log("valueValidate:",valueValidate?"Ok":"Invalid value")
    
    return (valueValidate);
  }


  checkSubject = (value) => {
    //return value.match(/[a-zA-Zéôüïî¨öêèéÄËÏÖÜ]+[-_]*[a-zA-Zéôüïî¨öêèéÄËÏÖÜ]+/i)
    console.clear()
    
    let trimmedValue = value.trim();

    console.log("trimmedValue:",trimmedValue)
    
    return (trimmedValue.length >1);
  }

  render() {
    // all available props
    const theme = {
      background: '#f5f8fb',
      fontFamily: 'Helvetica Neue',
      headerBgColor: 'orange',
      headerFontColor: '#fff',
      headerFontSize: '20px',
      botBubbleColor: 'orange',
      botFontColor: 'black',
      userBubbleColor: '#fff',
      userFontColor: '#4a4a4a',
    };

    return (
      <ThemeProvider theme={theme}>
      <ChatBot
        headerTitle={this.props.headerTitle}
        opened = {this.props.opened}
        floating = {true}
        placeholder = {"Préparation de votre requête ..."}
        hideSubmitButton = {false}
        //botAvatar = {}
        botAvatar = {botAvatarPicture}
        floatingIcon = {botAvatarPicture}
        steps={[
          {
            id: 'introduction',
            message: 'Bonjour',
            trigger: 'name-question',
          },
          {
            id: 'name-question',
            message: 'Quel est votre prénom ?',
            trigger: 'name',
          },

          {
            id: 'name',
            user: true,
            placeholder:"Entrez votre prénom...",
            validator: (value) => {
              if (!this.checkName(value)){
                return 'Nom trop court ou contient des chiffres';
              }
              return true;
            },
            trigger: 'email-question'
          },
         
          {
            id: 'email-question',
            message: 'Bienvenue {previousValue}, quelle est votre adresse e-mail ?',
            trigger: 'email',
          },
          
          {
            id: 'email',
            user: true,
            placeholder:"Entrez adresse e-mail...",
            validator: (value) => {
              if (!value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
                return 'Format attendu: jean@gmail.com';
              }
              return true;
            },
            trigger: 'whom',
          },
          
          {
            id: 'whom',
            message: 'Pour qui devons nous prier ?',
            trigger:'target_message',
          },
          {
            id: 'target_message',
            hideInput:true,
            options: [
                { value: 'me', label: 'pour vous-même', trigger: 'category-question' },
                { value: 'other', label: 'pour un bénéficiaire', trigger: 'target-name-question' },
              ],
          },
          {
            id: 'target-name-question',
            message: 'Comment s\'appelle ce bénéficiaire?',
            trigger: 'target',
          },
          {
            id: 'target',
            user: true,
            placeholder:"Entrez un prénom...",
            validator: (value) => {
              if (!this.checkName(value)){
                return 'Prénom trop court ou contient des chiffres';
              }
              return true;
            },
            trigger: 'category-question'
          },
          {
            id: 'category-question',
            message: "De quoi avez-vous besoin ?",
            trigger: 'category',
          },
          {
            id: 'category',
            options: [
                { value: '3', label: 'Renforcement spirituel', trigger: 'subject-question' },
                { value: '4', label: 'Guérison d\'une maladie', trigger: 'subject-question'},
                { value: '5', label: 'Soutien social', trigger: 'subject-question'},
                { value: '1', label: 'Support missionnaire', trigger: 'subject-question'},
              ],
          },
          {
            id: 'subject-question',
            message: "Décrivez succintement votre sujet de prière",
            trigger: 'subject',
          },
          {
            id: 'subject',
            user: true,
            placeholder:"Entrez le sujet...",
            validator: (value) => {
              if (!this.checkSubject(value)){
                return 'Le sujet est trop court';
              }
              return true;
            },
            trigger: 'summary'
          },
          
          {
            id: 'summary',
            message: 'Parfait, récapitulons votre sujet de prière',
            trigger: 'review',
          },
          {
            id: 'review',
            component: <Review />,
            asMessage: true,
            trigger: 'update',
          },
          {
            id: 'update',
            message: 'Voulez-vous modifier quelque-chose?',
            trigger: 'update-question',
          },
          {
            id: 'update-question',
            options: [
              { value: 'yes', label: 'Oui', trigger: 'update-yes' },
              { value: 'no', label: 'Non', trigger: 'ChatBotSumbmit' },
            ],
          },
          {
            id: 'update-yes',
            message: 'Que souhaiteriez-vous modifier ?',
            trigger: 'update-fields',
          },
          {
            id: 'update-fields',
            options: [
              { value: 'name', label: 'Votre prénom', trigger: 'update-name' },
              { value: 'email', label: 'Votre adresse email', trigger: 'update-email' },
              { value: 'target', label: 'Le bénéficiaire', trigger: 'update-target' },
              { value: 'category', label: 'La catégorie de prière', trigger: 'update-category' },
              { value: 'subject', label: 'Le sujet de prière', trigger: 'update-subject' },
            ],
          },
          {
            id: 'update-name',
            update: 'name',
            trigger: 'summary',
          },
          {
            id: 'update-email',
            update: 'email',
            trigger: 'summary',
          },
          {
            id: 'update-target',
            update: 'whom',
            trigger: 'target_message',
          },
          {
            id: 'update-category',
            update: 'category',
            trigger: 'summary',
          },
          {
            id: 'update-subject',
            update: 'subject',
            trigger: 'summary',
          },

          {
            id: 'ChatBotSumbmit',
            component: <ChatBotSumbmit/>,
            asMessage: true,
            trigger: 'restart-question',
          },

          {
            id: 'restart-question',
            message: 'Souhaitez-vous soumettre un nouveau sujet ?',
            trigger: 'restart',
          },
          {
            id: 'restart',
            options: [
              { value: 'yes', label: 'Oui', trigger: 'whom' },
              { value: 'no', label: 'Non', trigger: 'introduction' },
            ],
          },
          {
            id: 'stop',
            message: 'Merci, soyez bénis en Jésus-Christ',
            end:true,
          },
        ]}
      />
      </ThemeProvider>
    );
  }
}

export default ChatBotForm;